import styled from "styled-components";
import { device } from "../utils/deviceSizes";

export const IndexPageStyles = styled.div`
  display: flex;
  flex-dircetion: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .logo {
    width: 40%;
    height: 40%;
    position: absolute;
    top: 0%;
    left: 30%;

    @media ${device.tablet} {
      width: 80%;
      height: 80%;
    }
  }
  .loading {
    margin-top: 10%;
  }
`;
