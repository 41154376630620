"use client"
import React, { useEffect } from "react";
import { NextPage } from "next";
import LoaderComponent from '@components/Ui/Loader';
import { IndexPageStyles } from '@styles/IndexPage';
const IndexPage: NextPage = () => {
  return (
    <IndexPageStyles>
      <LoaderComponent className="loading-elements loading" />{" "}
      <img className="logo" src={"/images/logo.svg"} />
    </IndexPageStyles>
  );
};
export default IndexPage;
