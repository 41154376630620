import React from 'react';
import Loader, { Circles, TailSpin } from 'react-loader-spinner';

import styled from 'styled-components';
import { device } from '../../../utils/deviceSizes';

export const LoaderContainerStyles = styled.div`
  width: 100%;
  height: 50rem;

  display: flex;
  align-items: center;
  justify-content: center;

  &.orders-loading {
    width: 60%;
    @media ${device.tablet} {
      width: 100%;
    }
  }
`;

const LoaderComponent = ({ className = "" }) => {
  return (
    <LoaderContainerStyles className={className}>
      <TailSpin
        color="#EA4C2F"
        height={100}
        width={100}
      />
    </LoaderContainerStyles>
  );
};

export default LoaderComponent;
